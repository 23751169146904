import React from "react"
import { NormalField } from "reactstrap-form-fields"
import Button from "./btn"

let Form = () => (
  <form name="Contact Form" method="POST" netlify="true" data-netlify="true">
    <input type="hidden" name="form-name" value="Contact Form" />
    <NormalField name="Nom" />
    <NormalField name="Email" />
    <NormalField name="Tél" />
    <NormalField name="Message" type="textarea" />
    <Button type="submit">Envoyer</Button>
  </form>
)

export default Form
